import React, { useEffect, useState } from "react";
import AccountName from "../../../../components/account-name/account-name";
import { Skeleton } from "../../../../components/skeleton/skeleton";
import { accountsStatusSelector } from "../../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../../store/store";
import FilterInvestmentsModal from "./filter-investments-modal/filter-investments-modal";
import "./summary-filter.scss";
import {
  getDashFormattedDateStringFromISOString,
  isAfter,
  isBefore,
  subtractYears,
} from "../../../../util/date-util";
import { useDates } from "../../../../selectors/dates";
import { setInvestmentSummaryDates } from "../../../../reducers/dates";

type SummaryFilterProps = {
  beginDate?: string;
};

export default function SummaryFilter(props: SummaryFilterProps) {
  const { beginDate } = props;
  const [realStartDate, setRealStartDate] = useState("");

  const { available: accounts, selected: selectedAccounts } = useSelector(
    (state) => state.accounts
  );
  const {
    startDate,
    endDate,
    investmentSummaryStartDate,
    investmentSummaryEndDate,
    lastBusinessDate,
  } = useDates();
  const dispatch = useAppDispatch();
  useEffect(() => {
    // this effect is setting the oldest date a user can choose in the datepicker
    // start by defaulting to startDate because we always have it
    // (beginDate comes back async)
    // more info: NQMNY-774
    setRealStartDate(startDate!);

    if (beginDate?.length) {
      const beginDateIso = new Date(beginDate).toISOString();
      const now = new Date();
      const twoYearsAgo = subtractYears(now, 2).toISOString();

      // start date is always start of the year
      // if the begin date is after the start of the year,
      // don't let user select before it
      if (isAfter(beginDateIso, startDate!)) {
        setRealStartDate(beginDateIso);
      }

      // if the account was initialy funded over two years ago,
      // we don't have data so set min date to 2 years ago
      else if (isBefore(beginDateIso, twoYearsAgo)) {
        setRealStartDate(twoYearsAgo);
      }

      // initially funded less than two years ago but before the start of the year
      else if (isBefore(beginDateIso, startDate!)) {
        setRealStartDate(beginDateIso);
      }
    }
  }, [beginDate, startDate]);

  useEffect(() => {
    dispatch(
      setInvestmentSummaryDates({
        investmentSummaryStartDate: startDate!,
        investmentSummaryEndDate: endDate!,
      })
    );
  }, []);

  return (
    <>
      <div className="summary-filter" data-testid="summary-filter">
        <h2 className="h5">Customize your view</h2>
        <div className="row">
          <div className="col-xs-4">
            <strong>Dates:</strong>
            <div data-testid="dates">
              <Skeleton selectors={[accountsStatusSelector]} height={24}>
                <>
                  {getDashFormattedDateStringFromISOString(
                    investmentSummaryStartDate!
                  )}{" "}
                  -{" "}
                  {getDashFormattedDateStringFromISOString(
                    investmentSummaryEndDate!
                  )}
                </>
              </Skeleton>
            </div>
          </div>
          <div className="col-xs-4">
            <strong>Accounts:</strong>
            <div data-testid="accounts">
              <Skeleton
                selectors={[accountsStatusSelector]}
                height={24}
                count={1}
              >
                {accounts.length === selectedAccounts.length
                  ? "Viewing all account(s)"
                  : renderAccounts(selectedAccounts)}
              </Skeleton>
            </div>
          </div>
          <div className="col-xs-4">
            <FilterInvestmentsModal minDate={new Date(realStartDate)} />
          </div>
        </div>
      </div>
      <p data-testid="info-text">
        The following information is updated as of&nbsp;
        <Skeleton selectors={[accountsStatusSelector]} height={24} count={1}>
          {<>{getDashFormattedDateStringFromISOString(lastBusinessDate!)}</>}
        </Skeleton>
      </p>
    </>
  );
}

function renderAccounts(selectedAccounts: any) {
  return (
    <>
      <ul>
        {selectedAccounts.map((account: any, index: any) => {
          return (
            <li key={index} data-testid={`selectedAccount-${account.id}`}>
              <AccountName name={account.name} id={account.id} asLink={false} />
            </li>
          );
        })}
      </ul>
    </>
  );
}
