import { parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import BSModal from "react-bootstrap/Modal";
import { Skeleton } from "../../../../../components/skeleton/skeleton";
import { Account } from "../../../../../models/account";
import { setSelectedAccounts } from "../../../../../reducers/accounts";
import { useDates } from "../../../../../selectors/dates";
import {
  accountsStatusSelector,
  datesStatusSelector,
} from "../../../../../selectors/status";
import { useAppDispatch, useSelector } from "../../../../../store/store";
import {
  isAfter,
  isSame,
  parseISOStringIntoDate,
} from "../../../../../util/date-util";
import AccountFilter from "./account-filter";
import CustomDatePicker from "./custom-date-picker";
import "./filter-investments-modal.scss";
import StartDateErrorMessage from "./start-date-error-message";
import { setInvestmentSummaryDates } from "../../../../../reducers/dates";

type FilterInvestmentsModalProps = {
  minDate: Date;
};
export default function FilterInvestmentsModal(
  props: FilterInvestmentsModalProps
) {
  const { minDate } = props;
  const dispatch = useAppDispatch();

  const { available: accounts, selected: selectedAccounts } = useSelector(
    (state) => state.accounts
  );
  const [show, setShow] = useState(false);

  function showModal() {
    setShow(true);
  }

  function hideModal() {
    setShow(false);
  }

  const { startDate, endDate, lastBusinessDate } = useDates();
  const [tempSelectedAccounts, setTempSelectedAccounts] =
    useState<Account[]>(selectedAccounts);
  const [tempStartDate, setTempStartDate] = useState<Date>();
  const [tempEndDate, setTempEndDate] = useState<Date>();
  const noSelectedAccounts = tempSelectedAccounts.length === 0;
  const startDateIsInvalid = isAfter(getStart(), getEnd());

  function getStart(): Date {
    return tempStartDate ? tempStartDate : parseISOStringIntoDate(startDate!);
  }

  function getEnd(): Date {
    return tempEndDate ? tempEndDate : parseISOStringIntoDate(endDate!);
  }

  function changeFilters() {
    if (
      !isSame(parseISO(startDate!), tempStartDate) ||
      !isSame(parseISO(endDate!), tempEndDate)
    ) {
      const start = tempStartDate
        ? parseISO(getNeutralISOString(tempStartDate)).toISOString()
        : startDate!;
      const end = tempEndDate
        ? parseISO(getNeutralISOString(tempEndDate)).toISOString()
        : endDate!;
      dispatch(
        setInvestmentSummaryDates({
          investmentSummaryStartDate: start,
          investmentSummaryEndDate: end,
        })
      );
    }
    const accountIds = tempSelectedAccounts.map((account) => account.id);
    dispatch(setSelectedAccounts(accountIds));
    hideModal();
  }

  function getNeutralISOString(inputDate: Date) {
    const inputMonth =
      inputDate.getMonth() < 9
        ? `0${inputDate.getMonth() + 1}`
        : inputDate.getMonth() + 1;
    const inputDay =
      inputDate.getDate() < 10
        ? `0${inputDate.getDate()}`
        : inputDate.getDate();
    return `${inputDate.getFullYear()}-${inputMonth}-${inputDay}T00:00:00.000Z`;
  }

  useEffect(() => {
    setTempSelectedAccounts(selectedAccounts);
  }, [selectedAccounts]);

  return (
    <div className="filter-investments-modal">
      <Skeleton
        selectors={[accountsStatusSelector, datesStatusSelector]}
        height={24}
        width={100}
      >
        <button className="modal-link" onClickCapture={showModal}>
          <span>Change view</span>
        </button>
      </Skeleton>
      <BSModal
        show={show}
        size="lg"
        backdropClassName="modal-backdrop"
        animation={false}
        onHide={hideModal}
      >
        <BSModal.Header closeButton>
          <BSModal.Title as={"h2"}>Filter Investment Summary</BSModal.Title>
        </BSModal.Header>
        <BSModal.Body>
          {startDateIsInvalid && <StartDateErrorMessage />}
          <h3>Change Dates</h3>
          <div className="row">
            <div className="col-xs-6">
              <CustomDatePicker
                hasError={startDateIsInvalid}
                title="Start Date"
                date={getStart()}
                name={"start"}
                minDate={minDate}
                updateDate={(
                  date: Date,
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  e.preventDefault();
                  setTempStartDate(date);
                }}
                lastValidDate={parseISO(lastBusinessDate!)}
              />
            </div>
            <div className="col-xs-6">
              <CustomDatePicker
                hasError={startDateIsInvalid}
                title="End Date"
                date={getEnd()}
                name={"end"}
                minDate={minDate}
                updateDate={(
                  date: Date,
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  e.preventDefault();
                  setTempEndDate(date);
                }}
                lastValidDate={parseISO(lastBusinessDate!)}
              />
            </div>
          </div>
          <AccountFilter
            accounts={accounts}
            selectedAccounts={tempSelectedAccounts}
            setSelectedAccounts={setTempSelectedAccounts}
          />
        </BSModal.Body>
        <BSModal.Footer>
          <ModalFooter
            startDateIsInvalid={startDateIsInvalid}
            noSelectedAccounts={noSelectedAccounts}
            handleClick={changeFilters}
            hideModal={hideModal}
          />
        </BSModal.Footer>
      </BSModal>
    </div>
  );
}

type FooterProps = {
  startDateIsInvalid: boolean;
  noSelectedAccounts: boolean;
  handleClick: () => void;
  hideModal: () => void;
};

function ModalFooter(props: FooterProps) {
  return (
    <>
      <button
        type="button"
        className="btn cancel-button"
        onClick={props.hideModal}
      >
        Cancel
      </button>
      <button
        className="btn btn-primary"
        data-testid="continue-button"
        disabled={props.startDateIsInvalid || props.noSelectedAccounts}
        data-dismiss="modal"
        onClick={props.handleClick}
      >
        Continue
      </button>
    </>
  );
}
